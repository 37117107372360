//FONTS
@font-face
    font-family: 'Gotham Narrow'
    src: url('./fonts/GothamNarrow-Black.woff2') format('woff2'), url('./fonts/GothamNarrow-Black.woff') format('woff')
    font-weight: 900
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Gotham Narrow'
    src: url('./fonts/GothamNarrow-Bold.woff2') format('woff2'), url('./fonts/GothamNarrow-Bold.woff') format('woff')
    font-weight: bold
    font-style: normal

@font-face
    font-family: 'Gotham Narrow'
    src: url('./fonts/GothamNarrow-Book.woff2') format('woff2'), url('./fonts/GothamNarrow-Book.woff') format('woff')
    font-weight: normal
    font-style: normal

@font-face
    font-family: 'Helvetica Neue'
    src: url('./fonts/HelveticaNeue-Italic.woff2') format('woff2'),url('./fonts/HelveticaNeue-Italic.woff') format('woff')
    font-weight: normal
    font-style: italic

@font-face
    font-family: 'Helvetica Neue'
    src: url('./fonts/HelveticaNeue-Bold.woff2') format('woff2'),url('./fonts/HelveticaNeue-Bold.woff') format('woff')
    font-weight: bold
    font-style: normal

@font-face
    font-family: 'Helvetica Neue'
    src: url('./fonts/HelveticaNeue-Light.woff2') format('woff2'),url('./fonts/HelveticaNeue-Light.woff') format('woff')
    font-weight: 300
    font-style: normal

@font-face
    font-family: 'Helvetica Neue'
    src: url('./fonts/HelveticaNeue-Medium.woff2') format('woff2'),url('./fonts/HelveticaNeue-Medium.woff') format('woff')
    font-weight: 500
    font-style: normal

@font-face
    font-family: 'Helvetica Neue'
    src: url('./fonts/HelveticaNeue-LightItalic.woff2') format('woff2'),url('./fonts/HelveticaNeue-LightItalic.woff') format('woff')
    font-weight: 300
    font-style: italic

@font-face
    font-family: 'Helvetica Neue'
    src: url('./fonts/HelveticaNeue.woff2') format('woff2'),url('./fonts/HelveticaNeue.woff') format('woff')
    font-weight: normal
    font-style: normal

//COLORS
$green: #71d44c
$green2: #53b130
$black: #111
$gray0: #444
$gray1: #606060
$gray2: #999
$gray3: #ccc
$gray4: #eee
$gray5: #f1f1f1
$gray6: #f9fafb
$white: #fff
$orange: #ec7029
$orange2: #de590e
$blue: #2196F3
$red: #f44336
$yellow: #ffeb3b
$purple: #9c27b0



//RESET EVERYTHING
*
    margin: 0
    padding: 0
    outline: 0 none
    box-sizing: border-box

    ::-webkit-scrollbar
        width: 10px

    ::-webkit-scrollbar-track
        background: transparent
    
    ::-webkit-scrollbar-thumb
        background: $gray4

    ::-webkit-scrollbar-thumb:hover
        background: $gray3

.green
    background: $green
.green2
    background: $green2
.black
    background: $black
.gray
    background: $gray1
.gray2
    background: $gray2
.gray3
    background: $gray3
.gray4
    background: $gray4
.gray5
    background: $gray5
.gray6
    background: $gray6
.white
    background: #fff
.orange
    background: $orange

.c-white
    color: #fff
.c-green
    color: $green

$font1: 'Gotham Narrow',sans-serif
$font2: 'Helvetica Neue',sans-serif

.typo
    font-family: $font1
.typo2
    font-family: $font2

//SCROLLBARS
.scroll-y::-webkit-scrollbar
    width: 16px!important


.scroll-y::-webkit-scrollbar-thumb
    background: #f6f6f6!important
    border-radius: 100vh!important
    background-clip: padding-box!important
    box-shadow: inset 0 0 15px 15px #eeeff2!important
    border: 4px solid transparent!important

.scroll-y::-webkit-scrollbar-track
    background: #fff!important
    border: 3px solid transparent!important

html
body
#root
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    color: $black
    line-height: 100%

a
    color: $gray1
    text-decoration: none
    &:active,
    &:hover
        color: $gray0

a, strong, b
    font-weight: 600

button, .button
    font-size: 16px
    font-weight: 500
    font-family: $font2
    line-height: 120%

p, label, li, input, textarea, span, small
    font-family: $font2
    line-height: 120%
    font-weight: 400
    color: $gray1
    &:hover
        color: $gray0

h1,h2,h3,h4,h5,h6
    color: $black
    text-transform: none
    line-height: 100%
    letter-spacing: -1px
    &:first-of-type
        margin-top: 0

h1, .h1
    font-size: 44px
    margin: 20px 0
    //color: $gray
    font-weight: 900
    font-family: $font1
h2, .h2
    font-size: 36px
    margin: 20px 0
    font-family: $font1

h3, .h3
    font-size: 28px
    margin: 15px 0
    font-weight: 500
    font-family: $font2
    color: $gray2
h4, .h4
    font-size: 18px
    font-weight: 500
    text-transform: uppercase
    margin: 10px 0
    font-family: $font2
h5, .h5
    font-size: 16px
    font-weight: 500
    //text-transform: uppercase
    margin: 10px 0
    font-family: $font2
    letter-spacing: 0

p
    padding-bottom: 15px
    &:last-of-type
        padding-bottom: 0

//LAYOUT MODES
//FIXED FULL SCREEN FOR MOBILE COMPATIBILITY
.layout-fixed
    position: fixed
    inset: 0
    width: 100%
    height: 100%

.fixed
    position: fixed
.t0
    top: 0
.r0
    right: 0
.l0
    left: 0
.z99
    z-index: 99

.flex
    display: flex
.block
    display: block
.none
    display: none !important
.row
    flex-direction: row
.col
    flex-direction: column
.align-s
    align-items: flex-start
.align-c
    align-items: center
.align-e
    align-items: flex-end
.just-s
    justify-content: flex-start
.just-c
    justify-content: center
.just-e
    justify-content: end
.just-sb
    justify-content: space-between
.just-sa
    justify-content: space-around

.g40
    gap: 40px
.g20
    gap: 20px
.g15
    gap: 15px
.g10
    gap: 10px
.g5
    gap: 5px

//WIDTHS
.w100
    width: 100%
.w50
    width: 50%
.w33
    width: 33.33%
.w25
    width: 25%
.w10
    width: 10%
.wauto
    width: auto

//PADDINGS
.p40
    padding: 40px
.p20
    padding: 20px
.p10
    padding: 10px
.p40-20
    padding: 40px 20px
.p20-40
    padding: 20px 40px
.p5
    padding: 5px

.pl5
    padding-left: 5px
//MARGINS
.m20
    margin: 20px
.m10
    margin: 10px
.m5
    margin: 5px
.mt40
    margin-top: 40px

//DISABLERS
.ls-no
    list-style: none

//LAYOUT DERIVED CLASSES
.scroll-y
    overflow-y: scroll

//WRAPPER ELEMENTS
.wrapper
    max-width: 1280px
    width: 100%
    margin: 0 auto

// CURSORES
.cursor-pointer
    cursor: pointer

//LISTS
ul
    &.default
        display: flex
        flex-direction: column
        list-style: none
        gap: 5px 0
        li
            display: block
            line-height: 120%
            // gap: 0 5px
            // flex-direction: row

            // flex-wrap: wrap
            // justify-content: flex-start
            // align-items: flex-start
            &:before
                content: ""
                width: 15px
                height: 15px
                background: #000
                border-radius: 100%
                display: inline-block
                margin: 0 5px -2px 0
            a
                display: inline-block
                width: auto

            &.parent
                display: block
                &:before
                    margin-right: 5px

            ul
                padding-top: 0.5rem
                padding-left: 1.2rem
                padding-bottom: 0.25rem
                width: 100%
                li
                    &:before
                        background: #fff
                        border: 2px solid #000
                        width: 11px
                        height: 11px
                    ul
                        li
                            &:before
                                background: #999
                                border: 2px solid #999
                            ul
                                li
                                    &:before
                                        border-radius: 0
                                        width: 15px
                                        height: 15px
                                        border: none
                                        background: #000

                                    ul
                                        li
                                            &:before
                                                background: #fff
                                                border-radius: 0
                                                border: 2px solid #000
                                                width: 11px
                                                height: 11px
                                            ul
                                                li
                                                    &:before
                                                        background: #999
                                                        border: 2px solid #999
                                                        border-radius: 0
                                                        width: 11px
                                                        height: 11px

    &.stoplight
        li
            &.ok
                &:before
                    background: $green
            &.warning
                &:before
                    background: #FFD700
            &.error
                &:before
                    background: #F11818

    &.hierarchy
        position: relative
        padding-left: 20px
        list-style: none
        &:before
            content: ""
            width: 2px
            background: #ccc
            display: block
            position: absolute
            left: 0
            top: 10px
            height: calc(100% - 18px)

        li
            position: relative
            &:before
                content: ""
                height: 2px
                width: 15px
                background: #ccc
                display: block
                position: absolute
                left: -20px
                top: 10px
                border-radius: 0

        ul
            position: relative
            padding-left: 20px
            list-style: none
            &:before
                content: ""
                width: 2px
                background: #ccc
                display: block
                position: absolute
                left: 0
                top: 10px
                height: calc(100% - 18px)
            &:after
                content: ""
                width: 2px
                background: #ccc
                display: block
                position: absolute
                left: 0
                top: 0px
                height: 10px


ol
    padding-left: 1.2rem
    list-style: none
    counter-reset: section

    li

        &:before
            counter-increment: section
            content: counters(section, ".") " "

//BOXES / CARDS
.box
    border: 1px solid rgba(0,0,0,0.15)
    margin-bottom: 20px
    padding: 20px
    //width: auto
    width: 100%
    //box-shadow: 0 2px 0 2px 0 rgba(0,0,0,0.15)
    &.w-adjust
        width: max-content
        max-width: 100%
.card
    border: 1px solid rgba(0,0,0,0.15)
    padding: 20px
    //margin-bottom: 20px
    border-radius: 5px
    display: block
    box-shadow: 0 1px 2px 0px rgb(0 0 0 / 15%)
    background: #fff

    &.int
        padding: 0
        > *
            padding: 20px



    &.rows
        display: flex
        flex-direction: row
        > *
            border-right: 1px solid rgba(0,0,0,0.15)
            padding-right: 20px
            margin-right: 20px
            &:last-of-type
                border-right: none
                margin-right: 0
                padding-right: 0
        &.int
            > *
                margin-right: 0
                &:last-of-type
                    padding-right: 20px
    &.cols
        display: flex
        flex-direction: column
        > *
            border-bottom: 1px solid rgba(0,0,0,0.15)
            padding-bottom: 20px
            margin-bottom: 20px
            &:last-of-type
                border-bottom: none
                margin-bottom: 0
                padding-bottom: 0
        &.int
            > *
                margin-bottom: 0
                &:last-of-type
                    padding-bottom: 20px


//BUTTONS
button,.button
    display: inline-block
    padding: 10px 15px
    border: none
    background: $gray4
    color: $gray0
    border-radius: 3px
    border-bottom: 2px solid rgba(0,0,0,0.15)
    &:hover, &.hover
        cursor: pointer
        background: $gray3
    &:active, &.active
        margin-top: 4px
        padding: 6px 15px 8px
        border-top: 2px solid rgba(0,0,0,0.15)
        border-bottom: none


    &.important
        background: $gray1
        color: #fff
        &:hover, &.hover
            background: $gray0

    &.primary
        background: $green
        color: #fff
        &:hover, &.hover
            background: $green2
    &.caution
        background: $orange
        color: #fff
        &:hover, &.hover
            background: $orange2

    &[disabled]
        pointer-events: none
        border: 1px solid #dedddc
        color: #bdbbb9
        background-color: transparent

    &.loading
        padding-left: 40px
        background: url(../assets/imgs/loading.png) no-repeat scroll 10px center #fff
        background-size: 20px auto

//REUSABLE ELEMENTS OF LOGO NATURE
.logo
    display: flex
    align-items: center
    gap: 5px
    // width: 95px
    // height: 24px
    // background: url(../imgs/logo-full-green-horizontal.svg) no-repeat scroll center center transparent
    // background-size: contain
    font-size: 30px
    font-weight: bold
    line-height: 100%
    color: $black
    font-family: $font2
    span
        display: inline-block
        background: #71d44c
        color: #fff
        padding: 3px 5px
        font-weight: 500
        text-transform: uppercase
        font-size: 12px
        border-radius: 2px
        margin-top: 6px
        letter-spacing: 0.5px
        line-height: 100%

//CUSTOM
.nav
    border-bottom: 1px solid rgba(0,0,0,0.15)
    ul
        li
            a
                font-weight: 500

.main
    padding-top: 71px
    height: calc(100% - 71px)
    .wrapper
        height: 100%
.sidebar
    width: 250px
    border-right: 1px solid rgba(0,0,0,0.15)
    height: calc(100% - 71px)
    ul
        margin: 5px 0 20px

        li
            border-left: 2px solid transparent



            a
                width: 100%
                display: block
                font-family: $font2
                font-size: 13px
                font-weight: 400
            &:hover
                //background: $gray4
                border-left-color: $gray4
                a
                    color: $black
            &.selected
                //background: $gray3
                border-left: 2px solid $green
                a
                    color: $green
.content
    width: calc(100% - 250px)
    margin-left: 250px

    .section
        margin-bottom: 40px
        &:last-of-type
            margin-bottom: 0


//CUSTOMSç
.sampler
    margin: 20px 0
    padding: 20px
    border: 1px solid rgba(0,0,0,0.15)
.typo-list
    li
        border-bottom: 1px solid rgba(0,0,0,0.15)
        padding: 20px 0
        h1,h2,h3,h4,h5,h6
            margin: 0
        p
            padding: 0


//COMPLEX COMPONENTS: NAVBAR
.nav
    width: 100%
    border-bottom: 1px solid rgba(0, 0, 0, 0.15)
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    z-index: 99
    left: 0
    right: 0
    top: 0
    position: fixed
    background: #fff
    .wrapper
        width: 100%
        padding: 20px 40px
        display: flex
        justify-content: space-between
        align-items: center
        height: auto

        .icon
            cursor: pointer
            width: 30px
            height: 30px

            &:hover
                fill: $green

        .nav-menu
            display: flex

            .active
                color: $green

        .hamburger
            display: none

    @media screen
        @media (max-width: 1280px)
            .wrapper
                .nav-menu
                    display: none
                .hamburger
                    display: flex

    &.on
        z-index: 100
        height: 100%

    .navbar-menu
        width: 100%
        height: 100%
        z-index: 9999
        padding: 0
        align-items: center
        justify-content: center
        padding: 20px 0

        ul
            list-style: none
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            gap: 20px

            li
                font-size: 18px
                font-weight: 500
                text-align: center

                .active
                    color: $green
        

.position-relative
    position: relative

.tag
    background-color: $white
    border: 1px solid $gray3
    border-radius: 5px
    font-size: 0.8rem
    padding: 0.3rem
    text-transform: uppercase

    &.small
        font-size: 0.6rem
        padding: 0.3rem

    &.big
        font-size: 1rem
        padding: 0.5rem
        

.listing
    list-style: none
    width: 100%
    .item-listing
        border-bottom: 1px solid $gray3
        padding: 0.4rem 0.3rem
        display: flex
        align-items: center
        justify-content: space-between

        &:hover
            background: $gray4

        &.item-link
            cursor: pointer

input
    &.search
        border: 0px
        padding: 10px
        width: 100%

    &.number
        border: 1px solid $gray3
        padding: 10px
        width: 100%
    &.number-hidden-spinner
        border: 1px solid $gray3
        padding: 10px
        width: 100%
        -moz-appearance: textfield
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button
            -webkit-appearance: none
            margin: 0
       

            
    &.date
        border: 1px solid $gray3
        padding: 10px
        width: 100%
    &.time
        border: 1px solid $gray3
        padding: 10px
        width: 100%
    &.datetime-local
        border: 1px solid $gray3
        padding: 10px
        width: 100%



.content-table
    width: 100%
    overflow-x: auto
    background-color: $white
    padding: 12px 6px

    .filter
        display: flex
        gap: 15px
        margin-bottom: 20px
        justify-content: end
        flex-wrap: wrap

        label
            margin-bottom: 5px

        input
            border: 1px solid $gray3
            border-radius: 3px
            padding: 4px
            font-size: 16px
            height: 100%

    .table
        width: 100%
        font-family: $font2
        border-collapse: collapse
        border-spacing: 0

        .table-header
            font-weight: 600

            .table-row

                td
                    text-align: left
                    border-bottom: 2px solid $gray3
                    padding: 0 6px 10px
                    white-space: nowrap

                    .td-content
                        display: flex
                        align-items: center

                        .icon
                            cursor: pointer
                            width: 20px
                            height: 20px
                            margin-left: 5px
                            -webkit-transition: all 0.5s ease-out
                            -moz-transition: all 0.5s ease
                            -ms-transition: all 0.5s ease

                            &:up
                                -webkit-transform: rotate(-180deg)
                                -moz-transform: rotate(-180deg)
                                -ms-transform: rotate(-180deg)
                                transform: rotate(-180deg)
                        .arrow
                            margin: 5px 0 0 10px


        .table-body
            .table-row
                td
                    text-align: left
                    border-bottom: 1px solid $gray3
                    padding: 6px 6px
                    white-space: nowrap

                    &.btn
                        display: flex
                        gap: 10px
                        align-items: center

                    &.icon

                        svg
                            width: 24px
                            height: 24px

                    .icon
                        display: none
                        cursor: pointer
                        fill: $green
                        width: 20px
                        height: 20px
                        margin: 0 10px 0 0

                        &:hover
                            fill: $green2

                        @media screen
                            @media (max-width: 900px)
                                display: block


        .table-mobile
            background-color: $gray6

            .container
                display: flex
                align-items: center
                justify-content: space-between
                padding: 10px
                border-bottom: 1px solid rgba(0, 0, 0, 0.15)

                span
                    width: 50%
                    white-space: break-spaces

                    &.btn
                        display: flex
                        flex-direction: column
                        gap: 10px
                        align-items: flex-start

                    &.icon

                        svg
                            width: 24px
                            height: 24px


    .pagination
        display: flex
        align-items: center
        margin-top: 20px
        justify-content: end
        gap: 15px

        .pag

            ul
                list-style: none
                display: flex
                gap: 8px
                align-items: center
                padding: 0
                margin: 0

                li
                    padding: 0 7px 2px
                    background-color: $white
                    color: $black
                    border: 1px solid rgba(0, 0, 0, 0.15)
                    border-radius: 5px
                    cursor: pointer

                    &:hover, &.active
                        background-color: $green
                        color: $white
                        border: 1px solid $green

        .next, .prev
            display: flex
            align-items: center
            cursor: pointer

            &.disabled
                cursor: none
                pointer-events: none
                opacity: .2

            &:hover
                svg
                    fill: $green

.sidenav
  height: 100%
  width: 0
  position: fixed
  z-index: 100
  top: 0
  right: 0
  background-color: $white
  overflow-x: hidden
  transition: 0.5s
  border: 1px solid $gray3
  box-shadow: 0 0 10px rgba(0,0,0,0.15)

.sidenav-top
        display: flex
        justify-content: space-between
        align-items: center

        padding: 30px 30px 30px 30px

        h4
            margin: 0
            font-size: 1.2rem


        .close
            font-size: 34px
            margin-left: 0px
            margin-right: 0px
            color: #818181
            &:hover
                color: #000
                text-decoration: none
                cursor: pointer

.sidenav-contenido
        padding: 0px 30px 20px 30px
        text-decoration: none
        //font-size: 22px
        color: #818181
        display: block
        transition: 0.3s
.sidenav-bottom
        position: absolute
        display: flex
        justify-content: space-evenly
        align-items: center
        bottom: 30px
        width: 100%
        color: #818181

.dropdown
    display: inline-block
    //position: absolute

    //position: relative
    padding: 10px 15px
    border: none
    background: $white
    color: $gray0
    border-radius: 3px
    border: 1.5px solid $gray3
    font-size: 1rem
    min-width: 200px

    &:hover, &.hover
        cursor: pointer
        border: 1.5px solid $green
    &:active, &.active
        border: 1.5px solid $green


.arrow
    float: right
    margin-top: 10px
    transform: translateY(-50%)
    border-left: 5px solid transparent
    border-right: 5px solid transparent
    border-top: 5px solid $gray3
    transition: 0.3s
    &.active
        border-top: 5px solid $green

.dropdown-content
    
    position: absolute
    background-color: $white
    min-width: 200px
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2)
    z-index: 2
    border: 1.5px solid $gray3
    border-radius: 3px
    margin-top: 10px
    max-height: 200px
    overflow-y: auto
    margin-left: -15px

    .dropdown-item
        z-index: 1
        border-radius: 3px
        padding: 10px 15px 10px 15px
        font-size: 1rem
        &:hover
            background: $gray4
            cursor: pointer
        &.active
            background: $gray4
            cursor: pointer
            border: 1px solid $green
            border-radius: 3px
            padding: 10px 15px

.progress-container
    width: 100%
    display: flex
    align-items: center
    gap: 20px

    .linea-progress
        width: 100%
        height: 5px
        position: relative

        .back
            background-color: $gray3
            opacity: 0.4
            display: block
            height: 100%
            border-radius: 10px
            position: relative

        .progress
            background-color: $gray3
            display: block
            height: 100%
            border-radius: 10px
            position: absolute
            top: 0
            -webkit-transition: all 0.5s ease-out
            -moz-transition: all 0.5s ease
            -ms-transition: all 0.5s ease

            &.important
                background-color: $gray0

            &.primary
                background-color: $green

            &.caution
                background-color: $orange
            
            &.animate
                animation-duration: 5s
                animation-name: progress
                animation-iteration-count: infinite

            @keyframes progress
                0%
                    width: 0%
                
                100%
                    width: 100%
    p
        white-space: nowrap
        font-size: 16px
        margin: 0 0 2px 0
        padding-bottom: 0
                

.breadcrumb
    font-family: $font2
    display: flex
    align-items: center
    gap: 10px
    margin-bottom: 20px

    .breadcrumb-item
        //font-size: 16px
        font-weight: 500
        margin: 10px 0
        
        font-size: 14px
        color: $gray0
        &:hover
            cursor: pointer
            color: $green
        &.active
            color: $green


    .breadcrumb-icon
        width: 20px
        height: 20px
        margin: 0 10px 0 0

        &:hover
            fill: $green2

        @media screen
            @media (max-width: 900px)
                display: block

.switch
    position: relative
    display: inline-block
    width: 38px
    height: 22px

    input
        display: none

        &:checked + .slider
            background-color: $green

            &.blue
                background-color: $blue

            &::before
                -webkit-transform: translateX(16px)
                -ms-transform: translateX(16px)
                transform: translateX(16px)

    .slider
        position: absolute
        cursor: pointer
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: $gray3
        -webkit-transition: .4s
        transition: .4s
        border-radius: 10px

        &::before
            position: absolute
            content: ""
            height: 16px
            width: 16px
            left: 3px
            bottom: 3px
            background-color: $white
            -webkit-transition: .4s
            transition: .4s
            border-radius: 50%

.switch-code
    position: relative
    display: inline-block
    min-width: 190px
    height: 34px

    input
        display: none

        &:checked + .slider

            .after
                svg
                    fill: $blue

            .before
                svg
                    fill: $gray0

            &::before
                -webkit-transform: translateX(92px)
                -ms-transform: translateX(92px)
                transform: translateX(92px)

    .slider
        position: absolute
        cursor: pointer
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: $gray4
        -webkit-transition: .4s
        transition: .4s
        border-radius: 5px
        display: flex
        align-items: center
        // justify-content: space-around
        // gap: 15px

        &::before
            position: absolute
            content: ""
            height: 28px
            width: 92px
            left: 3px
            bottom: 3px
            background-color: $white
            -webkit-transition: .4s
            transition: .4s
            border-radius: 5px

        .before
            position: relative
            font-weight: 500
            left: 0
            width: 50%
            display: flex
            align-items: center
            justify-content: center
            gap: 5px

            svg
                height: 20px
                width: 20px
                fill: $blue

        .after
            position: relative
            font-weight: 500
            right: 0
            width: 50%
            display: flex
            align-items: center
            justify-content: center
            gap: 5px

            svg
                height: 20px
                width: 20px


.code-content
    width: 100%
    overflow-x: auto
    position: relative

    pre
        code
            font-family: $font2
            color: #71d44c

            .diff .hljs-meta, .hljs-keyword, .hljs-template-tag, .hljs-type
                color: #bf35de

            .hljs-class .hljs-title, .hljs-title, .hljs-title.class_, .hljs-attr
                color: #00ecff

            .hljs-addition, .hljs-code, .hljs-string, .hljs-title.class_.inherited__
                color: #de8a35

            .hljs-link, .hljs-literal, .hljs-number, .hljs-symbol, .hljs-variable.constant_, .hljs-function
                color: #0028ff

            .hljs-built_in, .hljs-doctag, .hljs-keyword.hljs-atrule, .hljs-quote, .hljs-regexp
                color: #ff0303

            .hljs-params
                color: #ded635

            .hljs-tag, .hljs-name
                color: #35de98

            .language-xml
                color: #00ff28

            span
                pointer-events: none

    .copy
        position: absolute
        right: 5px
        top: 5px
        background: transparent
        padding: 5px 8px
        border:  1px solid $gray1
        border-radius: 5px
        cursor: pointer

        svg
            color: $gray1
            height: 20px
            width: 20px

        &:hover
            border:  1px solid $gray2

            svg
                color: $gray2

                
        &.active
            border:  1px solid $green

            svg
                color: $green
       


.control
    position: relative
    display: flex
    align-items: center
    gap: 10px
    border: 1px solid $gray3
    width: 100%

    .input
        width: 100%
        padding: 10px 15px
        border: none
        border-radius: 3px
        font-size: 1rem
        font-family: $font2
        color: $gray0
        background: $white

    .icon
        position: relative
        width: 15px
        height: 15px
        fill: $gray3
        z-index: 4
        display: flex
        justify-content: center
        right: 15px

.modal
    position: absolute
    display: none
    align-items: center
    justify-content: center
    flex-direction: column
    overflow: hidden
    margin: 0
    padding: 0
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 99

    &.active
        display: flex

    .modal-container
        background-color: rgba(0,0,0,.1)
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0

    .modal-content
        display: flex
        flex-direction: column
        max-height: 100%
        width: 100%
        position: relative
        background-color: $white
        border-radius: 5px

        .header
            display: flex
            align-items: center
            padding: 20px
            background-color: $gray6
            border-bottom: 1px solid $gray4
            border-top-left-radius: 5px
            border-top-right-radius: 5px

            h2
                margin: 0
                font-size: 30px

            .icon
                width: 24px
                height: 24px
                cursor: pointer

                &:hover
                    fill: $green

        .body
            padding: 20px
            overflow-x: hidden
            overflow-y: auto




.uptime
    width: 100%

    .uptime-header
        display: flex
        align-items: center
        justify-content: space-between
        gap: 10px
        margin-bottom: 20px

        .uptime-title
            font-size: 20px
            font-weight: 500
            color: $gray0

        .uptime-subtitle
            font-size: 14px
            font-weight: 500
            color: $gray0

    .uptime-content
        display: flex
        align-items: center
        gap: 5px

        .uptime-box
            display: flex
            align-items: center
            border-radius: 5px
            height: 50px
            width: 15px
            
            &.green
                background: $green
            &.red
                background: $red
            &.yellow
                background: $yellow
            &.blue
                background: $blue
            &.grey
                background: $gray2
            
.tooltip
    position: relative
    display: inline-block

    .tooltip-text
        display: none
        // visibility: hidden
        height: 100%
        width: 120px
        background-color: $gray0
        color: $white
        text-align: center
        border-radius: 6px
        padding: 5px 0
        position: absolute
        z-index: 4
        bottom: 125%
        left: 50%
        margin-left: -60px
        // opacity: 0
        transition: opacity 0.3s
        // border: 1px solid $gray3

        &.top
            bottom: 125%
            left: 50%
            margin-left: -60px

        &.right
            bottom: 4%
            left: 125%
            margin-left: 0px

            &::after
                top: 25%
                left: -4%
                border-color: transparent $gray0 transparent transparent

        &.bottom
            top: 125%

            &::after
                top: -40%
                left: 50%
                border-color: transparent transparent $gray0 transparent

        &.left
            bottom: 4%
            left: -200%
            margin-right: 0px

            &::after
                top: 25%
                left: 104%
                border-color: transparent transparent transparent $gray0

        &.ml100
            margin-left: -100%
        
        &::after
            content: ""
            position: absolute
            top: 100%
            left: 50%
            margin-left: -5px
            border-width: 5px
            border-style: solid
            border-color: $gray0 transparent transparent transparent

    &:hover .tooltip-text
        display: block
        // visibility: visible
        // opacity: 1

.gs-content
    border: 1px solid $gray3
    font-family: $font2
    font-weight: 500
    border-radius: 5px
    padding: 10px
    display: flex
    flex-direction: column
    gap: 10px

    .gs-row
        display: flex
        align-items: center
        justify-content: space-between
        gap: 10px

        &:first-child

        .gs-column
            width: 100%
            display: flex
            align-items: center
            padding: 20px
            text-align: center

            &:first-child
                
            &.off
                background-color: $gray4
                    
            &.on
                background-color: $gray2
                color: $white

            &.ok
                background-color: $green
                color: $white

            &.warnig
                background-color: $yellow
                color: $white

            &.error
                background-color: $red
                color: $white

            &.cursor-pointer
                cursor: pointer

            &.text-left
                text-align: left

            &.text-right
                text-align: right

            &.text-justify
                text-align: justify

            .text
                width: 100%